<template>
  <section class="policy-list">
    <div
      class="header-title-back flex"
      @click="() => $router.back()"
    >
      <i class="el-icon-arrow-left"></i>
      <span>
        Settlements
      </span>
    </div>
    <div>
      <div>
        <span class="title">{{statementData.month}}</span>
        <div class="flex">
          <span  class="remark">Settle Date</span>
          <span>{{showHkDate(statementData.settle_date)}}</span>
        </div>
      </div>
      <div class="flex-coloumn">
        <span class="remark">Remark</span>
        <span>
          {{
            statementData.remark}}
        </span>
      </div>
    </div>
    <div class="show-table-maxscreen">
      <div
        class="flex-justify flex flex-content">
        <div
          class="flex title-tip">
          <div class="flex-cloumn">
            <span>Total Policies</span>
            <span class="number theme-blue">{{summary.policy_num||0}}</span>
          </div>
          <div class="flex-cloumn">
            <span>Total Premium </span>
            <span class="number theme-blue">{{summary.total_premium|formatMoney(2,true)}}</span>
          </div>
          <div class="flex-cloumn">
            <span>Total Commission for RM </span>
            <span class="number theme-blue">{{summary.owner_commission_vl|formatMoney(2,true)}}</span>
          </div>
          <div class="flex-cloumn">
            <span>Total Referrer fee</span>
            <span class="number theme-blue">{{summary.referrer_commission_vl|formatMoney(2,true)}}</span>
          </div>
        </div>

      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        :header-row-class-name="'table-header'"
        style="width: 100%"
      >
        <div >
          <el-table-column width="10">
          </el-table-column>
          <el-table-column
            width="130"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Issue Date</span>
                <v-date-picker placeholder="Range"></v-date-picker>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{showMonth(scope.row.issue_date)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="130"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Effective Date</span>
                <v-input placeholder="Search"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{showMonth(scope.row.effective_date)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==1"
            width="130"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Expiry Date</span>
                <v-input placeholder="Search"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{showMonth(scope.row.expiry_date)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==2"
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Insurer</span>
                <v-select :list="companyList"></v-select>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="flex flex-logo">
                <span
                  v-if="showOtherInsurer(scope.row.company_id)"
                  class="company-logo">
                  <img
                    :src="getOptionData('insureSVGOptions',scope.row.company_id).logo"
                    alt="company" />
                </span>
                <span  class="text-over-flow">{{getOptionName('globalOptions.company',scope.row.company_id)}}</span>

              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Product</span>
                <v-select :list="productList"></v-select>
              </div>
            </template>
            <template slot-scope="scope">
              <span  class="text-over-flow">{{ getOptionName('productTypeOptions',scope.row.product_id)}}</span>
            </template>

          </el-table-column>
          <el-table-column
            prop="status"
            width="220"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Policyholder</span>
                <v-input placeholder="Search"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{scope.row.policy_holder}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="policy_no"
            width="220"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Policy No.</span>
                <v-input placeholder="Search"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span
                class="link hand flex flex-aligin"
                @click="handlerPolicy(scope.row.order_no)">
                <font-awesome-icon icon="link"></font-awesome-icon>
                <span>{{ scope.row.policy_no||'-'}}</span>
              </span>
            </template>
          </el-table-column>


          <el-table-column
            prop="status"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Gross Premium</span>
                <v-input placeholder="Search"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.gross_premium|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==2"
            prop="status"
            width="120"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Premium Received</span>
                <v-input placeholder="Search"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.amount_received|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==1"
            prop="status"
            width="120"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Net Premium</span>
                <v-input placeholder="Search"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.insurer_net_vl|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==2"
            prop="status"
            width="120"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Comm. to Referrer</span>
                <v-input placeholder="Search"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.referrer_commission_vl|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            width="120"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Status</span>
                <v-input placeholder="Search"></v-input>
              </div>
            </template>
            <template  slot-scope="scope">
              <v-button
                :class="getOptionName('buttonColorClassOption',scope.row.policy_status)"
                disabled
                plain>{{getOptionName('statusOptions',scope.row.policy_status)}}</v-button>
            </template>
          </el-table-column>


        </div>
      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <div>
                <el-row>
                  <v-row-col>
                    <span class="is-bold">Issue Date</span>
                    <v-date-picker></v-date-picker>
                  </v-row-col>

                  <v-row-col>
                    <span class="is-bold">Effective Date</span>
                    <v-input placeholder="Search"></v-input>
                  </v-row-col>
                  <v-row-col v-if="type==1">
                    <span class="is-bold">Expiry Date</span>
                    <v-input placeholder="Search"></v-input>
                  </v-row-col>
                  <v-row-col v-if="type==2">
                    <span class="is-bold">Insurer</span>
                    <v-select :list="companyList"></v-select>
                  </v-row-col>
                  <v-row-col>
                    <span class="is-bold">Product</span>
                    <v-select :list="productList"></v-select>
                  </v-row-col>
                  <v-row-col>
                    <span class="is-bold">Policyholder</span>
                    <v-input placeholder="Search"></v-input>
                  </v-row-col>

                  <v-row-col>
                    <span class="is-bold">Policy No.</span>
                    <v-input placeholder="Search"></v-input>
                  </v-row-col>

                  <v-row-col >
                    <span class="is-bold">Gross Premium</span>
                    <v-input placeholder="Search"></v-input>
                  </v-row-col>
                  <v-row-col  v-if="type==2">
                    <span class="is-bold">Premium Received</span>
                    <v-input placeholder="Search"></v-input>
                  </v-row-col>
                  <v-row-col  v-if="type==2">
                    <span class="is-bold">Comm. to Referrer</span>
                    <v-input placeholder="Search"></v-input>
                  </v-row-col>
                  <v-row-col  v-if="type==1">
                    <span class="is-bold">Net Premium</span>
                    <v-input placeholder="Search"></v-input>
                  </v-row-col>
                  <v-row-col >
                    <span class="is-bold">Status</span>
                    <v-input placeholder="Search"></v-input>
                  </v-row-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table">
        <div
          @click="rowClick(item)">
          <div
            class="tr">
            <span>Issue Date</span>
            <span>{{showMonth(item.issue_date)}}</span>
          </div>

          <div
            class="tr">
            <span>Effective Date</span>
            <span>{{showMonth(item.effective_date)}}</span>
          </div>
          <div
            v-if="type==1"
            class="tr">
            <span>Expiry Date</span>
            <span>{{showMonth(item.expiry_date)}}</span>
          </div>
          <div
            v-if="type==2"
            class="tr">
            <span>Insurer</span>
            <span>{{item.insurer}}</span>
          </div>
          <div
            class="tr">
            <span>Product</span>
            <span>{{getOptionName('productTypeOptions',item.product_id)}}</span>
          </div>
          <div
            class="tr">
            <span>Policyholder</span>
            <span>{{item.policy_holder}}</span>
          </div>
          <div
            class="tr">
            <span>Policy No.</span>
            <span
              class="link"
              @click="handlerPolicy(item.order_no)">{{item.policy_no}}</span>
          </div>
          <div
            class="tr">
            <span>Gross Premium</span>
            <span>{{item.gross_premium|formatMoney(2,true)}}</span>
          </div>
          <div
            v-if="type==2"
            class="tr">
            <span>Premium Received</span>
            <span>{{ item.amount_received|formatMoney(2,true)}}</span>
          </div>
          <div
            v-if="type==2"
            class="tr">
            <span>Comm. to Referrer</span>
            <span>{{item.referrer_commission_vl|formatMoney(2,true)}}</span>
          </div>
          <div
            v-if="type==1"
            class="tr">
            <span>Net Premium</span>
            <span>{{item.insurer_net_vl|formatMoney(2,true)}}</span>
          </div>
          <div
            class="tr">
            <span>Status</span>
            <v-button
              :class="getOptionName('buttonColorClassOption',item.policy_status)"
              disabled
              plain>{{getOptionName('statusOptions',item.policy_status)}}</v-button>

          </div>
        </div>
      </div>
    </div>
    <v-pagination
      :total="pageConfig.total"
      :current="pageConfig.currentPage"
      @handle-current-change="currentChange"
      @handle-size-change="handleSizeChange"
    />
  </section>
</template>

<script>
import policiesSold from '@/views/member/componment/policiesSold'
import {referrerStatementDetail,referrerSummary} from '@api/referrer'
import {insurerStatementDetail,insurerSummary} from '@api/insurer'
import MixinOptions from '@/mixins/options'
import { mapState, mapActions } from 'vuex'
import MixinFormat from '@/mixins/format'

export default {
  name: 'settlementList',
  components:{
  },
  mixins: [MixinOptions,MixinFormat],
  data(){
    return{
      tableData:[],
      summary:{},
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        to:'',
        per_page:1,
        size:20
      },
      show:false
    }
  },
  computed:{
    ...mapState('statement',['statementData','statementParams']),
    type(){
      return Number(this.$route.query.type)
    },
    isAble(){
      return this.multipleSelection.length<=0
    },

    companyList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.company)
    },
    productList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.product)
    }
  },
  created(){
    if(this.type==1)
    {
      this.network().insurerStatementDetail()
      this.network().insurerSummary()
    }
    else
    {
      this.network().referrerStatementDetail()
      this.network().referrerSummary()
    }
  },
  methods:{
    showOtherInsurer(company_id) {
      let arr =  [1,4,11,3]
      return arr.includes(company_id)
    },
    handlerPolicy(order_no) {
      this.$router.push({
        path: '/quotation/premium/info',
        query: {
          order_no
        }
      })
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.network().referrerStatementDetail()
    },
    handleSizeChange(size){
      this.pageConfig.size =size
      this.network().referrerStatementDetail()
    },
    network() {
      return {
        referrerStatementDetail: async () => {
          let {month,settlement,referral} =this.statementParams||{}
          const { data } = await referrerStatementDetail({
            referral_id:referral,
            settlement_id:settlement,
            month:month,
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
          })
          let {meta} =data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
        insurerStatementDetail: async () => {
          let {company,settlement,month} =this.statementParams||{}

          const { data } = await insurerStatementDetail({
            company_id:company,
            settlement_id:settlement,
            month:month,
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
          })
          let {meta} =data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
        referrerSummary: async () => {
          let {month,settlement,referral} =this.statementParams||{}

          const { data } = await referrerSummary({
            referral_id:referral,
            settlement_id:settlement,
            month:month,
          })
          this.summary =data
        },
        insurerSummary: async () => {
          let {company,settlement,month} =this.statementParams||{}

          const { data } = await insurerSummary({
            company_id:company,
            settlement_id:settlement,
            month:month,
          })
          this.summary =data
        },
      }
    }
  }

}
</script>

<style scoped lang="scss">
  @import '../../assets/css/policyList';
  .remark{
    color: #4A5568;
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .title{
    font-size: 24px;
    color: #000000;
    display: block;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .flex-coloumn{
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    flex-wrap: wrap;
    word-break: break-word;
    .remark{
      display: block;

    }
    span{
      word-break: break-word;
    }
  }
  .header-title-back{
    font-size: 16px;
    font-weight: normal;
  }
  .flex-content{
    margin-bottom: 20px;
    .title-tip{
      font-size: 16px;
      font-weight: 600;
      .flex-cloumn:first-child{
        color: #000;
      }
      .flex-cloumn{
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        color: #4A5568;
        justify-content: space-between;
        .number{
          font-size: 28px;
          //color: #718096;
        }
      }
    }
    .flex-button{
      align-self: flex-end;
    }
  }
  .flex-logo{
    align-items: center;
    .company-logo{
      width: 30px;
      height: 20px;
      display: flex;
      img{
        width: 60%;
        display: block;
      }
    }
  }
  ::v-deep .table-slot-header{
    height: 71px!important;
  }
</style>
